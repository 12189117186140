<template>
  <div>
    <div class="modal-cover"/>
    <div class="terms-modal">
      <div class="top">
        <img class="logo" src="@/assets/logo_lamacat.png" alt="">
        <img class="cancel" src="@/assets/images/icon/icon_cancel.png" alt=""
             @click="closeModal">
      </div>
      <h1>무통장 입금</h1>
      <table class="payment-box">
        <tr>
          <th>입금은행</th>
          <td>{{ selectBank }}</td>
<!--          <td class="select-box">-->
<!--            <mdb-select v-model="bank" @getValue="getSelectValue" placeholder="-&#45;&#45;은행 선택-&#45;&#45;"></mdb-select>-->
<!--            <img class="select-icon" src="@/assets/images/icon/icon_gray_down.png"/>-->
<!--          </td>-->
        </tr>
        <tr>
          <th>송금자명</th>
          <td><input type="text" v-model="senderName"></td>
        </tr>
        <tr>
          <th>입금계좌번호</th>
          <td>{{ accountNumber }}</td>
        </tr>
        <tr>
          <th>입금기한</th>
          <td>결제 버튼 클릭 후 1시간 ({{ date }}) 까지</td>
        </tr>
      </table>
      <table class="payment-box">
        <tr>
          <th class="receipt-text" rowspan="2">
            <input class="form-check-input" type="checkbox" id="checkbox0" v-model="cashReceipt.isReceipt">
            <label for="checkbox0">현금영수증 발행</label>
          </th>
          <td>
            <mdb-form-inline :class="{disabled: !cashReceipt.isReceipt}">
              <mdb-input type="radio" id="receipt-1" name="groupRadios2" radioValue="individual" v-model="cashReceipt.type" label="소득공제용"/>
              <mdb-input type="radio" id="receipt-2" name="groupRadios2" radioValue="group" v-model="cashReceipt.type" label="지출증빙용"/>
            </mdb-form-inline>
          </td>
        </tr>
        <tr>
          <td class="tell">
            <p>휴대폰번호</p>
            <div>
              <input v-model="cashReceipt.mobile" type="tel" placeholder="예) 010-1234-1234" maxlength="13" :class="{disabled: !cashReceipt.isReceipt}">
            </div>
          </td>
        </tr>
      </table>
      <button class="custom-btn" @click="payment">결제</button>
    </div>
  </div>
</template>

<script>

import {mdbFormInline, mdbInput} from "mdbvue";
// import {mdbFormInline, mdbInput, mdbSelect} from "mdbvue";
import {getDate} from "@/lib/prettyDate";

export default {
  name: "PaymentWindow",
  components: {
    mdbInput,
    mdbFormInline,
    // mdbSelect
  },
  props: {},
  data() {
    return {
      senderName: '',
      accountNumber: '우리은행 황지창 1002-155-736702',
      date: '',
      cashReceipt: {
        isReceipt: false,
        type: '',
        mobile: '',
      },
      bank: [
        {text: '---은행 입력---', checked: true, disabled: true},
        {text: 'NH농협', value: 'NH농협'},
        {text: 'KB국민은행', value: 'KB국민은행'},
        {text: '기업은행', value: '기업은행'},
        {text: '우리은행', value: '우리은행'},
        {text: '신한은행', value: '신한은행'},
        {text: '하나은행', value: '하나은행'},
        {text: '전북은행', value: '전북은행'},
        {text: '수협은행', value: '수협은행'},
        {text: '우체국', value: '우체국'},
      ],
      selectBank: '우리은행',
    }
  },
  mounted() {

    this.updateNow()
    setInterval(this.updateNow.bind(this), 1000)
  },
  methods: {
    closeModal() {
      this.$emit('closePayment')
    },
    getSelectValue(value) {
      this.selectBank = value
    },
    payment() {
      const self = this;
      if (self.selectBank === '') return alert('은행을 선택해 주세요')
      if (self.senderName === '') return alert('송금자명을 입력해 주세요')
      if (self.cashReceipt.isReceipt) {
        if (self.cashReceipt.type === '') return alert('발행할 현금영수증 종류를 선택해주세요')
        if (self.cashReceipt.mobile === '') return alert('휴대폰 번호를 입력해 주세요')
        if (!self.checkMobile()) {
          return false
        }
      }
      self.onSend()
    },
    checkMobile() {
      const self = this;
      const exptext = /^\d{3}\d{3,4}\d{4}$/;
      const exptext2 = /^\d{3}-\d{3,4}-\d{4}$/;
      if (exptext.test(self.cashReceipt.mobile) === false && exptext2.test(self.cashReceipt.mobile) === false) {
        self.$swal.fire({title: '형식 오류', text: '전화번호 형식이 올바르지 않습니다.', icon: 'error'})
        return false;
      }
      self.cashReceipt.mobile = self.cashReceipt.mobile
          .replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3");
      return true;
    },
    updateNow() {
      const toDate = new Date()
      this.date = getDate(toDate)

      let hour = toDate.getHours() + 1
      let minutes = toDate.getMinutes()
      if (hour < 10) hour = `0${hour}`
      if (minutes < 10) minutes = `0${minutes}`
      this.date = this.date + ' ' + hour + ':' + minutes
    },
    onSend() {
      const self = this;

      const dataInfo = {
        isPayment: false,
        userInfo: {
          email: self.$store.state.user.email,
          uid: self.$store.state.uid,
        },
        timeToPay: self.date,
        paymentData: {
          bank: self.selectBank,
          senderName: self.senderName,
          cashReceipt: self.cashReceipt,
        }
      }
      self.$emit('addReceipt', 'withoutBankbook', dataInfo)
    }
  }
}
</script>

<style scoped>

.terms-modal {
  width: 612px;
  height: 718px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 60;
}

.terms-modal .top {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.terms-modal .top .logo {
  width: 103px;
  height: 18px;
}

.terms-modal .top .cancel {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.terms-modal h1 {
  text-align: start;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.32px;
  margin: 30px 0;
}

.terms-modal .payment-box {
  width: 100%;
  margin-bottom: 20px;
}

.terms-modal .payment-box tr {
  height: 52px;
  border-top: 1px solid #E4E4E7;
  border-bottom: 1px solid #E4E4E7;
  width: 100%;
  text-align: start;
}

.terms-modal .payment-box tr:first-child {
  border-top: 2px solid #E4E4E7;
}

.terms-modal .payment-box .receipt-text {
  border-bottom: 0;
}

.terms-modal .payment-box tr:last-child {
  border-bottom: 2px solid #E4E4E7;
}

.terms-modal .payment-box tr .select-box {
  position: relative;
}

.terms-modal .payment-box tr .select-box img {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
}


.terms-modal .payment-box tr th {
  background: #FAFAFA;
  padding-left: 16px;
}

.terms-modal .payment-box tr td input {
  width: 100%;
  outline: none;
}

.terms-modal .payment-box tr td {
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
}

.terms-modal .custom-btn {
  width: 100%;
}


.terms-modal .payment-box tr td::v-deep(.caret) {
  display: none;
}

.terms-modal .payment-box tr td::v-deep(.select-wrapper.md-form),
.terms-modal .payment-box tr td::v-deep(.md-form.select-dropdown) {
  margin: 0 !important;
}

.terms-modal .payment-box tr td::v-deep(.select-active input) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.terms-modal .payment-box tr td::v-deep(.collapse-item[data-v-39a3e486]) {
  top: 100%;
}


.terms-modal .payment-box tr td::v-deep(.dropdown-content.select-dropdown) {
  border-radius: 8px;
}

.terms-modal .payment-box tr td::v-deep(.select-inner-wrapper) {
  height: 100%;
}

.terms-modal .payment-box tr td::v-deep(.options-container[data-v-6c2c7caf]) {
  overflow-y: auto;
  height: 100%;
}

.terms-modal .payment-box tr td::v-deep(.select-inner-wrapper li) {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px #E4E4E7 solid;
}

input::placeholder,
.terms-modal .payment-box tr td::v-deep(.form-control)::placeholder {
  color: #C7C6CD;
}

.terms-modal .payment-box tr td::v-deep(.select-inner-wrapper li.active) {
  background: #fafafa;
}

.terms-modal .payment-box tr td::v-deep(.select-inner-wrapper li.active span) {
  color: #ff8a00;
}

.terms-modal .payment-box tr td::v-deep(.select-inner-wrapper li span) {
  font-size: 16px;
  color: #000000;
}

.terms-modal .payment-box tr td::v-deep(.md-form .form-control) {
  font-weight: 500;
  margin-bottom: 0 !important;
  padding: 0;
}

.terms-modal .payment-box tr td::v-deep(.md-form input:not(.browser-default)) {
  height: 32px;
  width: 100%;
  padding: 0 10px;
  background: #FFFFFF;
  margin-bottom: 20px;
  border: 1px #E4E4E7 solid;
  border-radius: 4px;
}

.terms-modal .payment-box tr td::v-deep(.md-form input:not(.browser-default):focus) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}


.payment-box::v-deep(.form-check-input[type="radio"]:checked+label),
.payment-box::v-deep(.form-check-input[type="radio"]:not(:checked)+label) {
  padding-left: 24px
}

.payment-box::v-deep(.form-check-input[type="radio"]:checked+label:after),
.payment-box::v-deep(.form-check-input[type="radio"]:checked+label:after) {
  background: #ff8a00;
  border: #ff8a00;
}

.form-check-input[type="checkbox"]:checked + label:before {
  border-right: 2px solid #ff8a00;
  border-bottom: 2px solid #ff8a00;
  border-top: 0;
  border-left: 0;
}

.payment-box tr .tell {
  display: flex;
  align-items: center;
  height: 52px;
}

.payment-box tr .tell p {
  margin-right: 10px;
}

.terms-modal .payment-box tr td input {
  height: 32px;
  border: 1px #E4E4E7 solid;
  padding: 0 10px;
  font-weight: 500;
}

</style>