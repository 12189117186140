<template>
  <div class="main-frame">
    <div class="main-container">
      <div class="payment">
        <div class="item-data">
          <div class="item-img"><img :src=itemData.thumbnail></div>
          <div class="item-text">
            <p class="subCategory">{{ itemData.subCategory }}</p>
            <h3>{{ itemData.title }}</h3>
            <p class="seller">판매자 <strong>{{ itemData.seller }}</strong></p>
          </div>
          <div class="price">
            <img src="../assets/images/icon/icon_recipt.png">
            {{ itemData.price }}원
          </div>
        </div>
        <div class="line"/>
        <div class="radio-check">
          <h4>결재방법</h4>
          <div class="radio-line">
            <mdb-form-inline>
              <mdb-input class="check" type="radio" id="payment-1" name="groupRadios2" radioValue="1" v-model="radioValue" label="무통장 입금"/>
              <mdb-input class="check" type="radio" id="payment-2" name="groupRadios2" radioValue="2" v-model="radioValue" label="실시간 계좌이체"/>
              <mdb-input class="check" type="radio" id="payment-3" name="groupRadios2" radioValue="3" v-model="radioValue" label="신용카드"/>
              <mdb-input class="check" type="radio" id="payment-4" name="groupRadios2" radioValue="4" v-model="radioValue" label="휴대폰"/>
            </mdb-form-inline>
          </div>
        </div>
        <div class="dropdown">
          <button class="tools-title" @click="dropdown.tool_1 = !dropdown.tool_1">결제 전 안내사항
            <img v-if="dropdown.tool_1" src="@/assets/images/icon/icon_black_up.png" alt="arrow">
            <img v-else src="@/assets/images/icon/icon_black_down.png" alt="arrow">
          </button>
          <slide-up-down :active="dropdown.tool_1" :duration="500">
            <div class="dropdown-text">
              안드로이드 스튜디오에서 Background Task를 이용하여 사용자의 스마트폰 내에 있는 음원을 재생하는 프로그램 패키지 입니다.
              상세하게 코드를 해석하면 background task를 이용하여 서버에서 데이터를 받는용도로 활용하거나 화면이 꺼진상태에서 다른 작업을 수행하게 하는등 응용해서 사용할 수 있도록 범용성을 높였습니다.
            </div>
          </slide-up-down>
        </div>
        <div class="dropdown">
          <button class="tools-title" @click="dropdown.tool_2 = !dropdown.tool_2">개인정보 제3자 제공
            <img v-if="dropdown.tool_2" src="@/assets/images/icon/icon_black_up.png" alt="arrow">
            <img v-else src="@/assets/images/icon/icon_black_down.png" alt="arrow">
          </button>
          <slide-up-down :active="dropdown.tool_2" :duration="500">
            <div class="dropdown-text">
              안드로이드 스튜디오에서 Background Task를 이용하여 사용자의 스마트폰 내에 있는 음원을 재생하는 프로그램 패키지 입니다.
              상세하게 코드를 해석하면 background task를 이용하여 서버에서 데이터를 받는용도로 활용하거나 화면이 꺼진상태에서 다른 작업을 수행하게 하는등 응용해서 사용할 수 있도록 범용성을 높였습니다.
            </div>
          </slide-up-down>
        </div>
      </div>
      <div class="purchase-data">
        <div class="purchase-text">주문금액 <span>{{ itemData.price }}원</span></div>
        <div class="purchase-text">수수료 <span>{{ fee.toLocaleString() }}원</span></div>
        <div class="purchase-text">쿠폰적용 <span>-</span></div>
        <div class="purchase-text">할인금액 <span>-</span></div>
        <div class="line"/>
        <div class="receipt">
          <div class="total">총 결제금액 <span>{{ totalPrice }}원</span></div>
          <div class="check-box">
            <input class="form-check-input" type="checkbox" id="checkbox" v-model="consent">
            <label for="checkbox">내용을 확인하였으며 결제에 동의합니다.</label>
          </div>
          <button class="custom-btn" @click="paymentWindow">결제하기</button>
        </div>
      </div>
    </div>
    <paymentWindow v-if="isShowPayment" @closePayment="closePayment" @addReceipt="addReceipt"/>
    <Progress :isProgress=isProgress></Progress>
  </div>
</template>

<script>
import {firestore, Timestamp} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import SlideUpDown from "vue-slide-up-down";
import {mdbFormInline, mdbInput} from "mdbvue";
import paymentWindow from "@/components/PaymentWindow.vue";
import store from "@/store";
import Progress from "@/components/Progress.vue";

export default {
  name: "ItemPurchase",
  components: {
    SlideUpDown,
    mdbInput,
    mdbFormInline, paymentWindow, Progress
  },
  data() {
    return {
      pageType: this.$route.params.type,
      uid: this.$route.params.uid,
      isProgress: false,
      itemData: {},
      totalPrice: 0,
      price: 0,
      fee: 5000,
      consent: false,
      isShowPayment: false,
      dropdown: {
        tool_1: true,
        tool_2: true,
      },
      radioValue: '1',
    }
  },
  mounted() {
    this.init()
  },
  watch: {},
  methods: {
    init() {
      const self = this;
      self.getItem()
    },
    getItem() {
      const self = this;
      firestore.collection('item')
          .doc(self.uid)
          .get()
          .then((snapshot) => {
            self.itemData = snapshot.data()
            self.price = parseInt(self.itemData.price)
            self.totalPrice = (self.price + self.fee).toLocaleString()

            const date = new Date(snapshot.data().lastDate.seconds * 1000);
            self.itemData.lastDate = getDate(date)
            self.itemData.price = self.itemData.price.toLocaleString()
            self.itemData['docId'] = snapshot.id
          })
    },
    paymentWindow() {
      const self = this;
      if (!self.consent) return alert('결제 동의를 체크해 주세요.')
      if (self.radioValue !== '1') return alert('준비중 입니다.')

      if (store.state.user)
        self.isShowPayment = true
      else return alert('결제를 하기 위해서 로그인을 해주세요')
    },
    closePayment() {
      const self = this;
      self.isShowPayment = false
    },
    addReceipt(paymentType, dataInfo) {
      const self = this;
      self.isProgress = true
      const _dataInfo = {...dataInfo}
      _dataInfo['paymentType'] = paymentType
      _dataInfo['regDate'] = Timestamp.fromDate(new Date())
      _dataInfo.paymentData['price'] = self.totalPrice
      _dataInfo['itemInfo'] = {
        title: self.itemData.title,
        docId: self.itemData.docId
      }
      firestore.collection('receipt')
          .add(_dataInfo)
          .then(() => {
            self.isProgress = false
            self.isShowPayment = false
            this.$router.push({name: 'ItemPurchaseSuccess'})
          })
    },
  }
}
</script>

<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
}

.main-frame .main-container {
  display: flex;
  padding: 60px 16px;
}

.main-frame .main-container .payment {
  width: 832px;
  height: 100%;
  margin-right: 60px;
}

.main-container .payment .item-data {
  height: 134px;
  display: flex;
  margin-bottom: 20px;
}

.main-container .payment .item-data .item-img {
  width: 200px;
  height: 134px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #A1A1AA;
  overflow: hidden;
  border: 1px solid #E4E4E7;
}

.main-container .payment .item-data img {
  width: 100%;
  height: 100%;
}

.main-container .payment .item-text {
  font-weight: 500;
  width: 502px;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 20px;
}

.main-container .payment .item-text .subCategory {
  color: #71717A;
  font-size: 14px;
  letter-spacing: -0.28px;
  margin-bottom: 10px;
}

.main-container .payment .item-text h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
}

.main-container .payment .item-text .seller {
  letter-spacing: -0.32px;
}

.main-container .payment .item-text .seller strong {
  font-weight: 700;
}

.main-container .payment .price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  white-space: nowrap;
}

.main-container .payment .price img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.main-container .payment .radio-check {
  margin-top: 20px;
}

.main-container .payment .radio-check h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: start;
  margin-bottom: 10px;
}

.main-container .payment .radio-check .radio-line {
  height: 20px;
  display: flex;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.main-container .payment .radio-check .radio-line .check {
}


.radio-line::v-deep(.form-check-input[type="radio"]:checked+label),
.radio-line::v-deep(.form-check-input[type="radio"]:not(:checked)+label) {
  padding-left: 22px
}

.radio-line::v-deep(.form-check-input[type="radio"]:checked+label:after),
.radio-line::v-deep(.form-check-input[type="radio"]:checked+label:after) {
  background: #ff8a00;
  border: #ff8a00;
}

.main-container .payment .radio-check .radio-line ::v-deep(.form-check-label.mr-5) {
  padding-left: 24px;
}

.dropdown .tools-title {
  display: flex;
  height: 42px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.24px;
  border-radius: 14px;
  background: #ffffff;
  border: 1px solid #E4E4E7;
  margin-top: 20px;
  margin-bottom: 10px;
}

.dropdown .tools-title img {
  width: 24px;
  height: 24px;
}
.dropdown .dropdown-text {
  padding: 0 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #A1A1AA;
}


.main-frame .main-container .purchase-data {
  display: grid;
  width: 300px;
  height: 328px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: #FAFAFA;
  gap: 10px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #71717A;
}

.main-container .purchase-data .total,
.main-container .purchase-data .purchase-text {
  display: flex;
  justify-content: space-between;
}

.line {
  height: 1px;
  width: 100%;
  background: #E4E4E7;
}

.purchase-data .receipt {
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #000000;
}

.purchase-data .receipt .total {
  line-height: 23px;
  margin-bottom: 40px;
}

.purchase-data .receipt .check-box {
  font-size: 14px;
}

.purchase-data .receipt .custom-btn {
  width: 100%;
}


.form-check-input[type="checkbox"] + label {
  margin: 0;
  padding-left: 24px;
  height: 18px;
  transform: translateY(-4px);
}

.form-check-input[type="checkbox"] + label:before {
  border: 2px solid #E4E4E7;
}

.form-check-input[type="checkbox"]:checked + label:before {
  border-right: 2px solid #ff8a00;
  border-bottom: 2px solid #ff8a00;
  border-top: 0;
  border-left: 0;
}

.check-box {
  margin-top: 10px;
  height: 20px;
  margin-bottom: 10px;
}

.check-box label {
  line-height: 23px;
}

</style>